
<template>
  <data-table-pagination
      ref="dataTable"
      :title="$t(translation + '.history')"
      :headers="headers"
      :translation="translation"
      :api-read="dataTablePaginationData.apiRead"
      :prop-filter="{credit_price_id: data.id}"
      :prop-sort-by="dataTablePaginationData.sortBy"

  >
    <template v-slot:toolbarAction>
      <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
        <v-icon dark>mdi-refresh</v-icon>
      </v-btn>
    </template>
  </data-table-pagination>
</template>


<script>

import {generateHeaders} from "@/utils/functions";
export default {
  name: "CreditPriceHistory",
  props: {
    data: {}
  },
  data() {
    return {
      translation: "credits_page",
      loading: false,
      dataTablePaginationData: {
        apiRead: '/api/credit_prices/credit-price-history',
        sortBy: ['updated_at'],
      },
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "price", text: this.$t(this.translation + '.price')},
      ])
    }
  },
  methods: {
    generateHeaders,

  }
}

</script>

<style scoped>

</style>