function setFocus(id) {
    console.log(id, "idd d")
    setTimeout(() => {
        document.getElementById(id).focus()
    })
}

function selectField(id) {
    setTimeout(() => {
        document.getElementById(id).select()
    }, 100)
}
/*---------------------------------- univerzalni search  ----------------------------------------*/
function search() {
    console.log("filters", this.filters);
    if (this.filteredTableData.length == 0) {
        this.filteredTableData = this.tableData
    }
    this.tableData = this.filteredTableData
    Object.keys(this.filters).forEach(val => {
        if (this.filters[val] == "") {
            return;
        }
        this.tableData = this.tableData.filter(item => {
            if (item != undefined && item != null) {
                //kreiraj varijablu koja sadrzi item
                let value = item;
                //ukoliko val koji predstavlja kljuc iz fitlera sadrzi tacku,
                //to znaci da treba uci u objekat, pa se to mora raditi preko []
                if (val.indexOf(".") != -1) {
                    //dobijamo svaki kljuc
                    let keys = val.split(".")
                    //prolazimo kroz svaki kljuc i ulazimo u value kroz loop i value dobija vrijednost od kljuca
                    keys.forEach(part => {
                        if (value != null) {
                            value = value[part];
                        }
                    })
                } else {
                    //ako nema tacke onda samo postaje kao sto pise
                    value = item[val];
                }
                return (value + "").toLowerCase().indexOf((this.filters[val] + "").toLowerCase()) > -1
            }
        })
    });
    if (this.tableData.length == 0) {
        this.errorMessage = this.$t('general.no_data');
    }
}
/*---------------------------------- univerzalni read (POST METHOD) ----------------------------------------*/
// eslint-disable-next-line no-unused-vars
function readTableDataPostPagination(apiRead, showOnlyActiveRows = false, showColumns = null, additionalParameters = null, isReport = false, reportName = 'report', reportType = 'pdf', reportIgnorePagination = false) {
    if (isReport) {
        this.reportDownloading = true;
    } else {
        this.tableDataLoading = true
        this.tableData = []
    }
    let data = {
        show_only_active: showOnlyActiveRows,
        show_columns: showColumns
    };
    let config = {};
    if (isReport) {
        config = {
            responseType: "blob"
        };
    }
    //dodaj dodatne parametre, ukoliko postoje
    data = {
        ...data,
        ...additionalParameters,
        is_report: isReport
    };

    // return;
    this.$axios.post(apiRead, data, config).then(response => {
        if (isReport) {
            const FileDownload = require('js-file-download');
            this.reportDownloading = false;
            FileDownload(response.data, reportName + '.' + reportType);
        } else {
            this.tableDataLoading = false
            this.tableData = response.data.result;
            // this.filteredTableData = response.data.result;
            this.totalRows = response.data.total_rows;
            if(this.tableData.length === 0) {
                this.errorMessage = this.$t('general.no_data')
            }
            this.isRefreshingData = false;
        }

    }).catch((error) => {
        if (isReport) {
            this.reportDownloading = false
        } else {
            this.tableDataLoading = false
        }

        if(error.response && error.response.data){
            this.$swal.fire({
                type: 'error',
                title: this.$t('general.error'),
                text: error.response.data?.message,
            })
            return
        }
        this.$swal.fire({
            type: 'error',
            title: this.$t('general.error'),
            text: this.$t('general.error_msg'),
        })

    });
}

/**
 * Univerzalna f-ja za prikaz swal popup za potvrdu
 * @param text
 * @param icon
 * @returns {*}
 */
function showSwalPopUpConfirmation(text, icon) {
    return this.$swal
        .fire({
            title: text ? text : "",
            icon: icon ? icon : "info",
            showCancelButton: true,
            confirmButtonColor: "#4caf50",
            cancelButtonColor: "#ff5252",
            reverseButtons: false,
            cancelButtonText: this.$t('general.cancel'),
            confirmButtonText: this.$t('general.confirm')
        })
}

function generateHeaders(headersArray) {
    return headersArray.map(item => {
        const text = item.text;
        const name = item.name || item.key;
        const align = item.align || 'left';
        const sortable = item.sortable ?? true; // ako postoji item.sortable onda to uzmi ako ne postoji ide true
        const value = item.value || item.key;
        const operator = item.operator || null;

        let header = {
            text: text,
            name: name,
            align: align,
            sortable: sortable,
            value: value,
            operator: operator
        }
        if (item.width) {
            header = {
                ...header,
                width: item.width
            }
        }
        return header;
    })
}

/**
 * F-ja za generisanje meta podataka za polja
 * @param data - mora biti niz
 * @returns {*}
 */
function generateFields(data) {
    // if(typeof data !== Array){
    //     console.log("fn-generateFields -> za data nije predat niz")
    //     return
    // }

    console.log("prolazi")
    // pravimo po default da bude text-field koji moze biti overwrite
    return data.map((item) => {
        return {
            cols: 12,
            hide_details: false,
            type: "text-field",
            rules: [],
            multiple: false,
            money: false,
            mask: null,
            single_select: true,
            options: {
                filters: {
                    is_active: ""
                },
                options: {
                    page: 1,
                    itemsPerPage: 1000,
                    sortBy: [
                        'updated_at'
                    ],
                    sortDesc: [
                        false
                    ],
                },
                is_single_level: true,
                show_inactive_items: false,
                is_report: false,
                show_columns: null,
                show_only_active: true,
            },
            ...item
        }
    })
}

function addItem() {
    this.form.item = null
    this.dialog.title = this.$t('general.add')
    this.dialog.show = true
}

function editItem(item) {
    this.form.item = null
    this.dialog.title = this.$t('general.edit')
    this.form.item = JSON.parse(JSON.stringify(item))
    this.dialog.show = true
}

async function deleteItem(item) {
    const response = await this.showSwalPopUpConfirmation(item.is_active ? this.$t("general.confirm_delete_message") : this.$t("general.confirm_return_message"), "info")
    if (response.isConfirmed) {
        this.deleteLoadingId = item.id

        this.$axios.delete(`${this.apiDelete}/${item.id}`).then((res) => {
            if (res && res.data) {
                this.dataTablePaginationData.deleteItem = res.data
                if(item.is_active) {
                    this.setSnackbar({color: "success", text: this.sucMessageDelete})
                } else {
                    this.setSnackbar({color: "success", text: this.sucMessageReturn})
                }
            }
        }).catch((err) => {
            if (err.response && err.response.data) {
                this.$swal.fire({
                    icon: "error",
                    title: this.$t('general.error'),
                    text: err.response.data.message
                })
                return
            }
            this.$swal.fire({
                icon: "error",
                title: this.$t('general.error'),
                text: this.$t('general.contact_admin_message')
            })
        }).finally(() => {
            this.deleteLoadingId = -1
        })
    }
}

function closeDialog() {
    this.dialog.title = ""
    this.dialog.show = false
}

/**
 * Funkcija koja nam handluje edit/store forme
 * Vazno: Potrebno je dodati sledece u data
 * 1. form
 * 2. dataTablePagination ?
 * 3. sucMessageEdit
 * 4. apiStore
 * 5. sucMessageStore
 * @param data
 */
function handleSubmitData(data) {

    this.loading = true
    // pozivamo Edit api
    if (this.form.item !== null) {
        // ako ocemo da saljemo FormData onda mora preko post !!!

        const config = {
            method: data instanceof FormData ? 'post' : 'put',
            url: `${this.apiUpdate}/${data instanceof FormData ? data.get('id') : data.id}`,
            data: data,
        }
        if(data instanceof FormData) {
            config.headers = {
                'Content-Type': 'multipart/form-data'
            };
        }
        this.$axios(config).then((response) => {
            if (response) {
                this.closeDialog()
                this.dataTablePaginationData.editItem = response.data
                this.setSnackbar({color: "success", text: this.sucMessageEdit})
            }
        }).catch((error) => {
            if (error.response && error.response.data) {
                this.$swal.fire({
                    icon: "error",
                    title: this.$t('general.error'),
                    text: error.response.data.message
                })
                return
            }
            this.$swal.fire({
                icon: "error",
                title: this.$t('general.error'),
                text: this.$t('general.contact_admin_message')
            })
        }).finally(() => {
            this.loading = false
        })
        return
    }
    this.$axios.post(this.apiStore, data, ).then((response) => {
        // console.log(response.data, "response data")
        if (response) {
            this.closeDialog()
            this.dataTablePaginationData.addItem = response.data
            this.setSnackbar({color: "success", text: this.sucMessageStore})
        }
    }).catch((error) => {
        if (error.response && error.response.data) {
            this.$swal.fire({
                icon: "error",
                title: this.$t('general.error'),
                text: error.response.data.message
            })
            return
        }
        this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: this.$t('general.contact_admin_message')
        })
    }).finally(() => {
        this.loading = false
    })
}

function getFormData() {
    this.$refs.refForm.getData()
}

export {
    setFocus,
    selectField,
    search,
    addItem,
    editItem,
    deleteItem,
    closeDialog,
    generateHeaders,
    readTableDataPostPagination,
    showSwalPopUpConfirmation,
    handleSubmitData,
    generateFields,
    getFormData
}
