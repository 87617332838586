<template>
  <div>
    <file-pond
        name="test"
        ref="pond"
        :files="files"
        :server="server"
        :allow-multiple="allowMultiple"
        :label-idle='label'
        :accepted-file-types="['image/png', 'image/jpeg']"
        @init="handleFilePondInit"
        v-on:addfile="handleProcessFile"

    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);
export default {
  name: "FilePondChild",
  props: {
    value: {},
    label: {
      type: String,
      default: "Prevuci failove odje...",
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      files: [],
      server: {
        load: (source, load) => {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'image/*'); // Add Content-Type header
          myHeaders.append('mode', 'no-cors')

          const myRequest = new Request(source, {
            method: 'GET', // Specify the HTTP method (GET by default)
            headers: myHeaders, // Pass the custom Headers object with the desired headers
          });

          fetch(myRequest)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.blob(); // Convert the response to a Blob
              })
              .then(myBlob => {
                console.log(myBlob, "myBlob");
                load(myBlob); // Process the Blob data
              })
              .catch(error => {
                console.error('Error fetching image:', error);
                load(undefined);
              });
        }
      }
    }
  },
  components: {
    FilePond,
  },
  watch: {
    'files': function (val) {
      console.log(val, "val")
    }
  },
  methods: {
    getPondFiles() {
      // console.log(, "getFiles")
      // returnthis.$refs.pond.getFiles()
      return this.$refs.pond.getFiles()
    },
    handleProcessFile() {
      console.log(this.$refs.pond.getFiles(), "getFiles")
    },
    handleFilePondInit() {
      if (this.value === '' || this.value === null) {
        this.files = null;
      } else {
        if(this.value.length > 0) {
          this.files = this.value.map((item) => {
            return {
              source: item,
              options: { type: 'local' }
            }
          })
        }
      }
    }

  }
}
</script>

<style>

.filepond--credits {
  display: none !important;
}

</style>