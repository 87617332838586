<template>
  <div>
    <!--    <table-picker/>-->
    <data-table-pagination
        ref="dataTable"
        :title="title ? title : $t(translation + '.title')"
        :headers="headers"
        :translation="translation"
        :append-item="dataTablePaginationData.addItem"
        :edit-item="dataTablePaginationData.editItem"
        :delete-item="dataTablePaginationData.deleteItem"
        :api-read="dataTablePaginationData.apiRead"
        :map-keys="dataTablePaginationData.mapKeys"
        :prop-filter="propsFilter"
        :prop-sort-by="['updated_at']"
        @success-delete="dataTablePaginationData.deleteItem = null"
        @success-append="dataTablePaginationData.addItem = null"
        @success-edit="dataTablePaginationData.editItem = null"
    >
      <template v-slot:toolbarAction>
          <v-btn
              class="ma-0 pa-0 mx-md-2 pa-md-2"
              color="primary"
              @click="addItem"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
            {{ $t('general.add') }}
          </v-btn>
          <v-btn
              class="ma-0 pa-0"
              color="warning"
              @click="$refs.dataTable.resetData()"
          >
            <v-icon dark>
              mdi-refresh
            </v-icon>
          </v-btn>
      </template>
      <template v-slot:action-buttons="{item}">
        <div class="d-flex flex-row">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="blue"
                  icon
                  @click="showAdInfo(item)"
              >
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </template>
            <span>{{$t(translation + '.ads_details')}}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  icon
                  @click="showContactHistory(item)"
              >
                <v-icon>mdi-clipboard-text-clock</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(translation + '.history_leads') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="warning"
                  icon
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('general.edit') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  icon
                  :loading="deleteLoadingId === item.id"
                  @click="deleteItem(item)">
                <v-icon>{{ item.is_active ? 'mdi-delete' : 'mdi-backup-restore' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.is_active ? $t('general.delete') : $t('general.return') }}</span>
          </v-tooltip>
        </div>
      </template>
    </data-table-pagination>

    <!--  ADD/EDIT DIALOG  -->
    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        width="800px"
        @close="dialog.title = ''; dialog.show = false"
    >
      <div v-if="dialog.show" >
        <add-edit ref="addEditAdd" :edit-item="form.item"/>
      </div>
      <template v-slot:dialogActions>
        <v-sheet class="text-end" width="100%">
          <v-spacer/>
          <v-btn @click="closeDialog" :disabled="loading" color="error">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
              class="ml-5"
              @click="getFormData"
              :loading="loading"
              color="primary">
            {{ form.item !== null ? $t('general.edit') : $t('general.add') }}
          </v-btn>
        </v-sheet>
      </template>
    </universal-dialog>
    <!-- SHOW LEADS HISTORY  -->
    <universal-dialog
        v-if="dialogLeadsHistory.show"
        :title="dialogLeadsHistory.title"
        :show-dialog="dialogLeadsHistory.show"
        width="1200px"
        @close="dialogLeadsHistory.title = ''; adId = null; dialogLeadsHistory.show = false"
    >
      <leads-history
         v-if="dialogLeadsHistory.show"
        :ad-id="adId"
      />
    </universal-dialog>
    <!-- ADD INFO  -->
    <universal-dialog
        class="ma-0 pa-0"
        v-if="infoModal.show"
        :title="infoModal.title"
        :show-dialog="infoModal.show"
        width="850px"
        @close="infoModal.title = ''; infoModal.ad = null; infoModal.show = false"
    >
      <info-dialog
        v-if="infoModal.show"
        :ad-data="infoModal.ad"
      />
    </universal-dialog>
  </div>
</template>

<script>
import UniversalDialog from "@/components/UniversalDialog";
import {CRUD_VARS} from "@/utils/consts";
import {mapMutations} from "vuex";
import {
  addItem,
  closeDialog,
  deleteItem,
  editItem,
  generateHeaders,
  showSwalPopUpConfirmation,
    handleSubmitData
} from "@/utils/functions";
import AddEditAd from "@/components/Ads/AddEditAd.vue";
import LeadsHistory from "@/views/LeadsHistory.vue";
import InfoDialog from "@/components/Ads/InfoDialog.vue";

export default {
  name: "AdsView",
  props: {
    title: {},
    propsFilter: {
      default: () => {}
    },
    propsHeaders: {
      default: () => []
    }
  },
  components: {
    InfoDialog,
    "universal-dialog": UniversalDialog,
    "add-edit" : AddEditAd,
    "leads-history" : LeadsHistory
  },
  data() {
    return {
      translation: "ads",
      loading: false,
      deleteLoadingId: -1,
      dataTablePaginationData: {
        apiRead: '/api/jobs/index',
        addItem: null,
        editItem: null,
        deleteItem: null,
        mapKeys: {
          'users.name': 'users.name',
          'ad_statuses.name' : 'ad_statuses'
        }
      },
      form: {
        item: ""
      },
      infoModal: {
        title: "",
        show: false,
        ad: null
      },
      dialogLeadsHistory: {
        show: false,
        title: ""
      },
      adId: null,
      ...CRUD_VARS
    }
  },
  computed: {
    headers() {
      return this.generateHeaders(this.propsHeaders.length > 0 ? this.propsHeaders : [
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "title", text: this.$t(this.translation + '.name')},
        {key: "users.name",  text: this.$t(this.translation + '.user_name'), operator: 'LIKE'},
        {key: "price_from", text: this.$t(this.translation + '.price_from'), operator: '>='},
        {key: "price_to", text: this.$t(this.translation + '.price_to'), operator: '<='},
        // {key: "description", text: this.$t(this.translation + '.description')},
        {key: "ad_statuses.name", text: this.$t(this.translation + '.status')},
        {key: "is_active", text: this.$t(this.translation + '.active')},
        {key: "actions", text: this.$t('general.actions'), sortable: false}
      ])
    },
  },
  created() {
    this.apiStore = "/api/jobs/store"
    this.apiUpdate = "/api/jobs/update"
    this.apiDelete = "/api/jobs/destroy"
    this.sucMessageEdit = this.$t(this.translation + '.sucMessageEdit')
    this.sucMessageStore = this.$t(this.translation + '.sucMessageStore')
    this.sucMessageDelete = this.$t(this.translation + '.sucMessageDelete')
    this.sucMessageReturn= this.$t(this.translation + '.sucMessageReturn')
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    closeDialog,
    addItem,
    showSwalPopUpConfirmation,
    generateHeaders,
    editItem,
    deleteItem,
    handleSubmitData,
    showAdInfo(item) {
      this.infoModal.ad = item
      this.infoModal.title = this.$t(this.translation + '.details_about_ad')
      this.infoModal.show = true
    },
    showContactHistory(item) {
      this.adId = item.id
      this.dialogLeadsHistory.title = this.$t(this.translation + '.history_leads')
      this.dialogLeadsHistory.show = true
    },
    async getFormData(){
      this.loading = true
      const data = await this.$refs.addEditAdd.getData()

      if(data === null) {
        this.loading = false
        return
      }
      try {
        this.handleSubmitData(data)
      } catch (e) {
        console.log(e, "eeee")
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
