export const validationRules = {
    requiredFloatValue: value => parseFloat(value) > 0 || 'Unesite vrijednost',
    required: value => !!value || 'Obavezno polje.',
    arrayRequired: v => v.length > 0 || 'Obavezno polje',
    requiredPrice: v => parseFloat(v) > 0 || 'Obavezno polje',
    minLength: (min, errorMessage) => value => (value && value.length >= min) || errorMessage || `Minimum ${min} karaktera.`,
    maxLength: (max, errorMessage) => value => (value && value.length <= max) || errorMessage || `Maks ${max} karaktera.`,
    email: value => /.+@.+\..+/.test(value) || 'E-mail mora biti u formatu pr. test@test.com.',
    // You can define more validation rules here as needed
};
