<template>
  <div>
    <!--    <table-picker/>-->
    <data-table-pagination
        ref="dataTable"
        :title="$t(translation + '.title')"
        :headers="headers"
        :translation="translation"
        :api-read="dataTablePaginationData.apiRead"
        :prop-sort-by="dataTablePaginationData.sortBy"

        :prop-filter="{}"
    >
      <template v-slot:toolbarAction>
        <v-tooltip top>
          <template
              v-slot:activator="{ on, attrs }">
            <v-btn
                class="primary ml-1"
                :disabled="$refs.dataTable ? ($refs.dataTable.$data.tableDataLoading || $refs.dataTable.$data.tableData.length === 0) : $refs.dataTable"
                :loading="$refs.dataTable ? $refs.dataTable.$data.reportDownloading : false"
                v-bind="attrs"
                v-on="on"
                @click="openDownloadReportDialog"
            >
              <v-icon class="white--text ">mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{$t('general.download_report')}}</span>
        </v-tooltip>
        <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </data-table-pagination>
    <download-report
        :showDialog="downloadReportDialog.show"
        :defaultName="downloadReportDialog.name"
        @download="downloadReport"
        @close="closeDownloadReportDialog"
    />
  </div>
</template>

<script>
import {generateHeaders, showSwalPopUpConfirmation} from "@/utils/functions";
import {mapMutations} from "vuex";
import DownloadReportDialog from "@/components/DownloadReportDialog.vue";

export default {
  name: "ReportsView",
  components:{
    "download-report" : DownloadReportDialog

  },
  data() {
    return {
      translation: "reports",
      loading: false,
      dataTablePaginationData: {
        apiRead: '/api/transactions/index',
        sortBy: ['updated_at'],
      },
      downloadReportDialog: {
        show: false,
        name: ""
      },
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "users.full_name", text: this.$t(this.translation + '.user')},
        {key: "description", text: this.$t(this.translation + '.description')},
        {key: "credit_amount", text: this.$t(this.translation + '.credit_amount')},
        {key: "credit_state", text: this.$t(this.translation + '.credit_state')},
      ])
    },
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    showSwalPopUpConfirmation,
    generateHeaders,
    openDownloadReportDialog() {
      // naziv izvjestaja
      this.downloadReportDialog.name = "transakcije"
      this.downloadReportDialog.show = true
    },
    closeDownloadReportDialog() {
      this.downloadReportDialog.name = ""
      this.downloadReportDialog.show = false
    },
    downloadReport(data) {
      this.$refs.dataTable.refreshData(false, false, true, data.name, data.type, data.show_all);
    },
  }
}
</script>

<style scoped>

</style>