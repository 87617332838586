import axios from "axios";
const state = () => ({
    isAuthenticated: false,
    user: {
        id: null,
        name: "",
        email: "",
        phone: "",
        details: "",
        image_url: ""
    },
    loading: false
});

const getters = {
    getUserData(state) {
        return state.user
    },
    getIsAuthenticated(state) {
        return state.isAuthenticated
    },
    getLoading(state) {
        return state.loading
    }
};

const actions = {
    async userProfile({commit}) {
        try {
            commit('setLoading', true)
            const response = await axios.get('/api/user');
            if(response && response.data){
                commit("setUser", response.data)
                // localStorage.setItem('isAuthenticated', true)
            }
        } catch (error) {
            if(error.response && error.response.data){
                this.$swal.fire({
                    icon: "error",
                    title: this.$t('general.error'),
                    text: error.response.data.message
                })
                return
            }
            this.$swal.fire({
                icon: "error",
                title: this.$t('general.error'),
                text: this.$t('general.contact_admin_message')
            })
            // localStorage.setItem('isAuthenticated', false);
        } finally {
            commit('setLoading', false)
        }
    }
};

const mutations = {
    setLoginStatus(state,data) {
        state.loginStatus = data;
    },
    setLoading(state, data) {
        state.loading = data
    },
    setUser(state, data) {

        console.log(data, "Data")
        const user = {
            id: data.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            details: data.details,
            image_url: data.image_url
        }
        state.user = user
        state.isAuthenticated = true
    },
    setLogout(state) {
        const user = {
            id: null,
            name: "",
            email: "",
            phone: "",
            details: "",
            image_url: ""
        }
        state.user = user
        state.isAuthenticated = false
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
