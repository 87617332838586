<template>
  <div class="ma-0 pa-0">
    <v-form ref="form" v-on:submit.prevent class="ma-0 pa-0" enctype="multipart/form-data">
      <v-row class="ma-0 pa-0">
        <!--     NAZIV   -->
        <v-col class="ma-0 pa-0 px-1 gaptest" cols="12">
          <v-text-field
              class="ma-0 pa-0"
              v-model="form.title"
              :label="$t(translation + '.name') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>
        <!--    USLUGE    -->

        <v-col class="ma-0 pa-0 px-1 gaptest" cols="6">
          <!--   TREE VIEW SELECT -->
          <tree-view-select
              ref="treeSelect"
              :label="$t(translation + '.services')"
              v-model="form.services"
              api-read="/api/services/index_active"
              translation="services"
              :multiple-select="false"
              :rules="[validationRules.arrayRequired]"
          />
        </v-col>
        <!--    KORISNIK    -->

        <v-col class="ma-0 pa-0 px-1 gaptest" cols="6">
          <table-picker
              ref="userTablePicker"
              v-model="form.posted_by_user_id"
              :label="$t(translation + '.user')"
              :rules="[validationRules.arrayRequired]"
              api-read="/api/users/index"
              :single-select="true"
              :table-header="userHeaders"
              item-value="id"
              item-text="name"
              width-dialog="1000px"
          />
        </v-col>
        <!--    IZABERI DRZAVE    -->
        <v-col
            class="ma-0 pa-0 px-1 gaptest"
            cols="12"
        >
          <v-checkbox
              v-model="form.allCountries"
              class="ma-0 pa-0"
              hide-details
              :label="$t(translation + '.chose_country')"
              @click="handleAllCountries"
          />
        </v-col>
        <!--    DRZAVE    -->

        <v-col
            v-if="form.allCountries"
            class="ma-0 pa-0 px-1 gaptest"
            cols="12"
        >
          <v-card elevation="0" class="ma-0 pa-0">
            <v-list-item-group class="ma-0 pa-0" style="border: 1px solid #443062; border-radius: 5px">
              <v-list-item
                  class="ma-0 pa-0"
                  v-for="(place, index) in form.places"
                  :key="index"
              >
                <v-list-item-content>
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0 px-1" cols="4">
                      <v-autocomplete
                          class="ma-0 pa-0"
                          :label="$t(translation + '.country')"
                          v-model="place.os_country_id"
                          :items="countries"
                          item-text="name"
                          item-value="id"
                          :loading="countriesLoading"
                          auto-select-first
                          autocomplete="off"
                          hide-details
                          @change="place.cities = []; disableSelectedCountries(); "
                          :no-data-text="countriesLoading ? $t('general.loading_msg') : $t('general.no_data')"
                          outlined
                          dense
                      />
                    </v-col>
                    <v-col class="ma-0 pa-0 px-1" cols="6">
                      <table-picker
                          v-model="place.cities"
                          :label="$t(translation + '.cities')"
                          hide-details
                          :rules="[]"
                          api-read="/api/os_cities/index_active"
                          :single-select="false"
                          :table-header="citiesHeader"
                          :prop-filter="{os_country_id: place.os_country_id}"
                          item-value="id"
                          item-text="name"
                          :disabled="!place.os_country_id || countriesLoading"
                      />
                    </v-col>
                    <v-col class="ma-0 pa-0 d-flex" cols="2">
                      <v-btn
                          icon
                          outlined
                          class="pa-0 ma-0 ml-2"
                          @click="removeAdditionalPlace(place, index)"
                      >
                        <v-icon
                            class="ma-0 pa-0"
                            color="error"
                        >
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                      <v-btn
                          v-if="index === form.places.length-1"
                          icon
                          outlined
                          class="pa-0 ma-0 ml-2"
                          @click="addAdditionalPlaces()"
                      >
                        <v-icon
                            class="ma-0 pa-0"
                            color="primary"
                        >
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-col>
        <!--    IZABERI RADNE DANE    -->
        <v-col
            class="ma-0 pa-0 px-1 gaptest"
            cols="12"
        >
          <v-checkbox
              v-model="form.allWorkingDays"
              class="ma-0 pa-0"
              hide-details
              :label="$t(translation + '.chose_working_days')"
              @click="handleAllWorkingDays"
          />
        </v-col>
        <!--    RADNI DANI    -->

        <v-col
            v-if="form.allWorkingDays"
            class="ma-0 pa-0 px-1 gaptest"
            cols="12"
        >
          <business-hours ref="businessHours" :data="form.ad_days"/>
        </v-col>

        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="4">
          <table-picker
              ref="currencyTablePicker"
              v-model="form.currency_id"
              :label="$t(translation + '.currencies')"
              :rules="[validationRules.arrayRequired]"
              api-read="/api/currencies/index"
              :single-select="true"
              :table-header='[{key: "name", text: this.$t("general.name")}]'
              item-value="id"
              item-text="name"
              width-dialog="500px"
          />

        </v-col>

        <!--    CIJENA OD    -->
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="4">
          <v-text-field
              class="ma-0 pa-0"
              v-model="form.price_from"
              :label="$t(translation + '.price_from') + ' *'"
              :rules="[validationRules.requiredPrice]"
              validate-on-blur
              v-money="moneyFloat"
              outlined
              dense
          />
        </v-col>
        <!--    CIJENA DO    -->

        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="4">
          <v-text-field
              class="ma-0 pa-0"
              v-model="form.price_to"
              :label="$t(translation + '.price_to')"
              :rules="[v => checkPrice(v)]"
              v-money="moneyFloat"
              outlined
              dense
          />
        </v-col>
        <!--    OPIS    -->

        <v-col class="ma-0 pa-0 px-1 gaptest" cols="12">
          <v-textarea
              class="ma-0 pa-0"
              :label="$t(translation + '.description')"
              v-model="form.description"
              :rules="[]"
              name="input-7-1"
              filled
              outlined
              auto-grow
          />
        </v-col>
        <v-col class="ma-0 pa-0 px-1 gaptest elevation-5" cols="12">

          <div class="text-subtitle-1 black--textreturn  ml-3 my-3"  v-if="show_upload === false">
            {{$t(translation + '.gallery')}}
          </div>

          <file-pond
              class="pa-2"
              ref="file-pond"
              :label="$t(translation + '.drag_image')"
              v-model="form.photos"
              :allow-multiple="true"
          />
        </v-col>
      </v-row>
    </v-form>

  </div>

</template>
<script>
import axios from "axios"
import TablePicker from "@/components/TablePicker.vue";
import BusinessHour from "@/components/BusinessHour.vue";
import TreeViewSelect from "@/components/TreeViewSelect.vue";
import FilePond from "@/components/FilePond.vue";
import {validationRules} from "@/utils/validationRules";

export default {
  name: "AddEditAdd",
  props: {
    editItem: {
      default: null
    },

  },
  components: {
    "tree-view-select": TreeViewSelect,
    "table-picker": TablePicker,
    "business-hours": BusinessHour,
    "file-pond" : FilePond
  },
  data() {
    return {
      translation: "ads",
      form: {
        title: '',
        allCountries: false,
        allWorkingDays: false,
        places: [],
        ad_days: [],
        price_from: '',
        currency_id: [],
        price_to: '',
        description: '',
        services: [],
        posted_by_user_id: [],
        photos: []
      },
      show_upload: true,
      countries: [],
      countriesLoading: false,
      moneyFloat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
    }
  },
  computed: {
    validationRules() {
      return validationRules
    },
    countryHeader() {
      return [
        {key: "name", text: this.$t(this.translation + '.countryHeaders.name')}
      ]
    },
    citiesHeader() {
      return [
        {key: "name", text: this.$t(this.translation + '.citiesHeader.name')}
      ]
    },
    currenciesHeader() {
      return [
        {key: "name", text: this.$t(this.translation + '.currenciesHeader.name')}
      ]
    },
    userHeaders() {
     return [
       {key: "name", text: this.$t(this.translation + '.userHeaders.user_name')},
       {key: "email", text: this.$t(this.translation + '.userHeaders.email')},
       {key: "phone", text: this.$t(this.translation + '.userHeaders.phone')},
     ]
    }
  },
  created() {
    if (this.editItem) {
      this.fillForm()
    }
    this.getCountries()
  },
  methods: {
    getImageType(url) {
      // Get the file extension from the URL
      const extension = url.substring(url.lastIndexOf('.') + 1);
      // Map common file extensions to image types
      switch (extension.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
          return 'jpeg';
        case 'png':
          return 'png';
      }
    },
    checkPrice(val) {
      if(parseFloat(val) === 0){
        return true
      }
      return parseFloat(val) > 0 && parseFloat(val) > parseFloat(this.form.price_from) || this.$t(this.translation + '.validation_price_msg')
    },
    async fetchImageAndAddToPond(url){
      try {
        // Fetch the image data
        const response = await axios.get(url, {
          responseType: 'blob',
        });

        // Convert blob to File object
        const filename = url.substring(url.lastIndexOf('/') + 1);
        const file = new File([response.data], filename, { type: response.data.type });

        // Add the file to FilePond
        this.form.photos.push(file);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    },
    async getData() {

      // console.log(this.$refs.treeSelect.validate(), "validate")

      const validatePicker = this.$refs.treeSelect.validate()

      const validateUserPicker = this.$refs.userTablePicker.validate()

      console.log(validatePicker, "validatePicker")
      if(!this.$refs.form.validate() || validatePicker === false || validateUserPicker === false){
        return null
      }

      let formData = new FormData()

      if(this.form.allWorkingDays) {
        this.form.ad_days = await this.$refs.businessHours.getWorkingDays()
      }

      if(this.form.ad_days.length > 0) {
        this.form.ad_days = this.form.ad_days.map((item) => {

          console.log(item.times, "item.times")
          if(item.times.length > 0 && item.times[0].start_at ==''){
            item.times = []
          }
          return item
        })
      }

      // if(this.form.places[0].os_country_id === '') {
      //   this.form.places = []
      // }

      // console.log(this.form.services, "form.services")
      this.form.services = this.form.services.filter((item) => item !== undefined)


      // ako je nula saljemo backend prazan string 
      if(parseFloat(this.form.price_to) === 0) {
        this.form.price_to = ''
      }

      const images = this.$refs["file-pond"].getPondFiles()

      images.forEach((item, index) => {
        formData.append(`photos[${index}]`, item.file)
      })

      for (const key in this.form) {
        if (key === 'photos') {
          continue
        }
        if(key === 'posted_by_user_id') {
          formData.append('posted_by_user_id', this.form.posted_by_user_id[0]?.id)
          continue
        }
        if(key === 'currency_id') {
          formData.append('currency_id', this.form.currency_id[0]?.id)
          continue
        }
        if (key === "places" || key === "ad_days" || key === 'services') {
          // this.form[key].forEach((item, index) => {
          //   formData.append(`${key}[${index}]`, JSON.stringify(item))
          // })
          formData.append(key, JSON.stringify(this.form[key]))
          continue
        }
        formData.append(key, this.form[key])
      }

      return formData
    },

    deleteImage(photo) {
      this.form.photos = this.form.photos.filter((item) => item.name !== photo.name)
    },
    handleAllWorkingDays() {

    },
    handleAllCountries() {
      if (this.form.allCountries) {
        this.form.places = [
          {
            os_country_id: "",
            cities: [],
          },
        ]
      }
    },
    removeAdditionalPlace(place, index) {
      console.log(this.form.places, "form.places")
      if(this.form.places.length === 1) {
        this.form.places = [
          {
            os_country_id: "",
            cities: [],
          },
        ]
      } else {
        this.form.places.splice(index, 1)
      }
      this.disableSelectedCountries()
    },
    disableSelectedCountries() {
      this.countries.forEach(item => {
        item.disabled = false
        let findObj = this.form.places.find(place => place.os_country_id == item.id)
        if (findObj) {
          item.disabled = true
        }
      })

    },
    addAdditionalPlaces() {
      this.form.places.push({os_country_id: "", cities: []})
    },
    fillForm() {
      this.form.id = this.editItem.id
      this.form.title = this.editItem.title
      this.form.price_from = parseFloat(this.editItem.price_from).toFixed(2)
      this.form.price_to = parseFloat(this.editItem.price_to).toFixed(2)
      this.form.description = this.editItem.description
      this.form.services = this.editItem.services
      this.form.posted_by_user_id = [this.editItem.users]
      this.form.ad_days = this.editItem.ad_days
      this.form.currency_id = [this.editItem.ad_currency]
      // this.fetchImageAndAddToPond("https://picsum.photos/200/300")
      // 'http://192.168.10.101:92/ad_images/2/7edc566dc255fa0dd96f67b1f6636f53.jpeg'
      this.form.photos = this.editItem.ad_photos != null ? this.editItem.ad_photos.map((item) => {

        let newUrl = item.photo_url.replace('/jobs_images', '/api/image/jobs_images')
        return newUrl;

      }) : []
      // this.form.photos = ["https://picsum.photos/200/300"]
      this.show_upload = false
      // ako postoje dani onda cekiraj izaberi radne dane
      if (this.editItem.ad_days.length > 0) {
        this.form.allWorkingDays = true
      }
      if (this.editItem.ad_countries.length > 0) {
        this.form.allCountries = true
        this.form.places = []
        this.editItem.ad_countries.map(item => {
          this.form.places.push({
            os_country_id: item.os_country_id,
            cities: item.ad_places.map((tItem) => {
              return tItem.os_cities
            })
          })
        })
      }
    },
    getCountries() {
      this.countriesLoading = true
      this.$axios.post('/api/os_countries/index_active', {
        filters: {
          is_active: ''
        },
        show_inactive_items: false,
        is_report: false,
        show_only_active: true,
      }).then((response) => {
        this.countries = response.data
        this.disableSelectedCountries()
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.countriesLoading = false
      })
    },
    handleSelected(item) {
      console.log("handleSelected", item)

      this.countries = this.unchangedCountries.filter(tItem => !this.form.places.some(place => place.os_country_id == tItem.id))
    }
  }
}
</script>

<style scoped>
.gaptest {
  gap: 1em;
}
</style>