<template>
  <div class="ma-0 pa-0">
    <v-form ref="form" v-on:submit.prevent class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <table-picker
              ref="countryTablePicker"
              v-model="form.os_country_id"
              :label="$t(translation + '.country')"
              :rules="[validationRules.arrayRequired]"
              api-read="/api/os_countries/index_active"
              :single-select="true"
              :table-header='[{key: "name", text: $t("general.name")}]'
              item-value="id"
              item-text="name"
              width-dialog="500px"
              :disabled="!!editItem?.id"
          />
        </v-col>
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <table-picker
              ref="currencyTablePicker"
              v-model="form.currency_id"
              :label="$t(translation + '.currency')"
              :rules="[validationRules.arrayRequired]"
              api-read="/api/currencies/index_active"
              :single-select="true"
              :table-header='[{key: "name", text: $t("general.name")}]'
              item-value="id"
              item-text="name"
              width-dialog="500px"
              :disabled="!!editItem?.id"
          />
        </v-col>
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <v-text-field
              class="ma-0 pa-0"
              v-model="form.price_per_credit"
              :label="$t(translation + '.price')"
              v-money="moneyFloat"
              validate-on-blur
              :rules="[validationRules.requiredFloatValue]"
              outlined
              dense
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {validationRules} from "@/utils/validationRules";
import TablePicker from "@/components/TablePicker.vue";
export default {
  name: "AddEditCredits",
  props: {
    editItem: {
      default: null
    },
    translation: {}
  },
  components: {
    "table-picker": TablePicker,
  },
  data() {
    return {
      form: {
        os_country_id: [],
        currency_id: [],
        price_per_credit: ""
      },
      moneyFloat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
    }
  },
  computed: {
    validationRules() {
      return validationRules
    }
  },
  created() {
    if (this.editItem) {
      this.fillForm()
    }
  },
  methods: {

    fillForm() {
      console.log(this.editItem, "this.ediTItem")
      this.form.id = this.editItem.id

      this.form.os_country_id = [this.editItem.os_countries]
      this.form.currency_id = [this.editItem.currencies]
      this.form.price_per_credit = parseFloat(this.editItem.price_per_credit).toFixed(2)
    },
    async getData() {
      const validateCountryPicker = this.$refs.countryTablePicker.validate()
      const validateCurrencyPicker = this.$refs.currencyTablePicker.validate()

      if(!this.$refs.form.validate() || validateCountryPicker === false || validateCurrencyPicker === false) {
        return null
      }

      let result = {
        os_country_id:  this.form.os_country_id[0].id,
        currency_id: this.form.currency_id[0].id,
        price_per_credit: this.form.price_per_credit
      }

      if(this.editItem?.id) {
        result.id = this.editItem.id
      }

      return result
    },
  }
}
</script>

<style scoped>

</style>