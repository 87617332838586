<template>
  <div class="ma-0 pa-0">
    <v-form ref="form" v-on:submit.prevent class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">

        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <v-text-field
              v-model="form.name"
              :label="$t(translation + '.name_field') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>
        <!--    OPIS    -->
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <v-textarea
              :label="$t(translation + '.description_field')"
              v-model="form.description"
              name="input-7-1"
              filled
              outlined
              auto-grow
          />
        </v-col>

        <v-col class="ma-0 pa-0 px-1" v-if="selectedData.selected_item" cols="12">
          <v-checkbox
              class="ma-0 pa-0"
              :label="$t(translation + '.service')"
              v-model="form.is_service"
              @change="form.credit = ''"
              hide-details
          />
        </v-col>
        <v-col class="ma-0 pa-0 px-1 flex-row elevation-3" cols="12">
          <div class="text-subtitle-1 black--text ml-3 image-icon my-3 text--primary">
            Slika
          </div>
          <file-pond
              class="pa-2"
              ref="file-pond"
              label="Prevucite sliku ovdje"
              v-model="form.icon"
              :allow-multiple="false"
          />
        </v-col>
        <v-col v-if="form.is_service" class="ma-0 pa-0 px-1 gaptest mt-4" cols="12">
          <v-text-field
              v-model="form.credit"
              :label="$t(translation + '.credits_field') + ' *'"
              :rules="[validationRules.required]"
              v-mask="['######']"
              outlined
              dense
          />
        </v-col>
      </v-row>
    </v-form>
    <div class="caption mt-3">{{$t('general.validationMsg')}}</div>

  </div>
</template>

<script>
/* eslint-disable */

import {validationRules} from "@/utils/validationRules";
import FilePond from "@/components/FilePond.vue";

export default {
  name: "AddEditService",
  components: {
    "file-pond": FilePond
  },
  props: {
    editItem: {
      default: null
    },
    translation: {},
    selectedData: {}
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        is_service: false,
        credit: '',
        icon: ''
      },
      moneyFloat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
    }
  },
  computed: {
    validationRules() {
      return validationRules
    }
  },
  created() {
    if (this.editItem) {
      this.fillForm()
    }
  },
  methods: {
    fillForm() {
      this.form.name = this.editItem.name
      this.form.description = this.editItem.description
      this.form.is_service = this.editItem.is_service
      this.form.credit = this.editItem.credit
      this.form.id = this.editItem.id
      this.form.icon = this.editItem.icon_url === '' ? [] : [this.editItem.icon_url.replace('/service_icons', '/api/image/service_icons')]
      // this.form.image_file = this.editItem.photo_url === '' ? [] : [this.editItem.photo_url.replace('/user_images', '/api/image/user_images')]
    },
    async getData() {
      if(!this.$refs.form.validate()) {
        return null
      }

      let formData = new FormData()

      const getImageFile = await this.$refs["file-pond"].getPondFiles()

      console.log(getImageFile, "getImageFiles")
      if(getImageFile.length > 0) {
        formData.append('icon', getImageFile[0].file)
      }

      for (const key in this.form) {
        if (key === 'icon') {
          continue
        }
        formData.append(key, this.form[key])

      }
      return formData
    },
  }
}
</script>

<style scoped>
.image-icon{
  font-family: Montserrat, sans-serif !important;

}
</style>