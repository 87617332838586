<template>
  <div>
    <data-table-pagination
        ref="dataTable"
        :title="$t(translation + '.notification')"
        :headers="headers"
        :translation="translation"
        :append-item="dataTablePaginationData.addItem"
        :api-read="dataTablePaginationData.apiRead"
        :prop-sort-by="dataTablePaginationData.sortBy"

        @success-append="dataTablePaginationData.addItem = null"
    >
      <template v-slot:toolbarAction>
        <v-btn color="primary" @click="addItem()">
          <v-icon dark>mdi-plus</v-icon>
          {{ $t('general.add') }}
        </v-btn>
        <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </data-table-pagination>

    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        @close="closeDialog"
    >
      <add-notification ref="addNotification"/>

      <template v-slot:dialogActions>
        <v-sheet class="text-end" width="100%">
          <v-spacer/>
          <v-btn @click="closeDialog" :disabled="loading" color="error">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
              class="ml-5"
              @click="getFormData"
              :loading="loading"
              color="primary">
            {{ form.item !== null ? $t('general.edit') : $t('general.add') }}
          </v-btn>
        </v-sheet>
      </template>
    </universal-dialog>
  </div>
</template>
<script>
import DataTablePagination from "@/components/DataTablePagination";
import UniversalDialog from "@/components/UniversalDialog";
import {CRUD_VARS} from "@/utils/consts";
import {mapMutations} from "vuex";
import {
  addItem,
  closeDialog,
  generateHeaders,
  generateFields,
  showSwalPopUpConfirmation
} from "@/utils/functions";
import AddNotification from "@/components/Notifications/AddNotification.vue";
export default {
  name: "NotificationsView",
  components: {
    "add-notification": AddNotification,
    "data-table-pagination": DataTablePagination,
    "universal-dialog": UniversalDialog,
  },
  data() {
    return {
      translation: "notification",
      loading: false,
      dataTablePaginationData: {
        apiRead: '/api/notifications/index',
        addItem: null,
        sortBy: ['updated_at'],

      },
      form: {
        item: ""
      },
      ...CRUD_VARS
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "subject", text: this.$t(this.translation + '.title')},
        {key: "notification_text", text: this.$t(this.translation + '.body')},
        {key: "updated_at", text: this.$t(this.translation + '.date')},
        {key: "users.name", text: this.$t(this.translation + '.user_name')},
        {key: "user_types.name", text: this.$t(this.translation + '.user_type')},

      ])
    },
  },
  created() {
    this.apiStore =  "/api/notifications/store"
    this.sucMessageStore= this.$t(this.translation + '.sucMessageStore')
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    closeDialog,
    addItem,
    generateFields,
    showSwalPopUpConfirmation,
    generateHeaders,
    submitData(data) {
      data.notification_for_users = data?.notification_for_users.map((item) => item.id)
      data.notification_user_types = data?.notification_user_types.map((item) => item.id)

      this.loading = true
      this.$axios.post(this.apiStore, data).then((response) => {
        // console.log(response.data, "response data")
        if (response) {
          this.closeDialog()
          // this.dataTablePaginationData.addItem = response.data
          this.$refs.dataTable.resetData()
          this.setSnackbar({color: "success", text: this.sucMessageStore})
        }
      }).catch((error) => {
        if (error.response && error.response.data) {
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.loading = false
      })
    },
    async getFormData(){
      this.loading = true
      const data = await this.$refs.addNotification.getData()

      if(data === null) {
        this.loading = false
        return
      }

      try {
        this.submitData(data)
      } catch (e) {
        console.log(e, "eeee")
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>