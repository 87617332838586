<template>
  <data-table-pagination
      ref="dataTable"
      :title="$t(translation + '.history')"
      :headers="headers"
      :translation="translation"
      :api-read="dataTablePaginationData.apiRead"
      :prop-filter="{service_id: data.id}"
      :disable-cus-template="['coins']"
      :prop-sort-by="dataTablePaginationData.sortBy"

  >
    <template v-slot:toolbarAction>
      <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
        <v-icon dark>mdi-refresh</v-icon>
      </v-btn>
    </template>
  </data-table-pagination>
</template>

<script>
import {generateHeaders} from "@/utils/functions";

export default {
  name: "ServiceCreditHistory",
  props: {
    data: {}
  },
  data() {
    return {
      translation: "services",
      loading: false,
      dataTablePaginationData: {
        apiRead: '/api/services/service-credits-history',
        sortBy: ['updated_at'],
      },
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "credit", text: this.$t(this.translation + '.credit')},
      ])
    }
  },
  methods: {
    generateHeaders,

  }
}
</script>

<style scoped>

</style>