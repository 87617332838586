<template>
  <div>
    <tree-view :translation="translation" :show-loading="showLoading" :items="items">
      <template v-slot:toolbarAction>
        <v-btn color="primary" :loading="addLoading" @click="addService()"> <v-icon dark>mdi-plus</v-icon>{{ $t('general.add') }}</v-btn>
        <v-btn class="ml-2" color="warning" @click="getData()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-slot:tree-body="{item, active}">
        <v-row align="center" class="my-0">
          <span class="mx-3 ml-7">
            <v-icon :color="!item.is_active ? 'error' : ''">
              {{!item.is_service ? 'mdi-folder' : 'mdi-tools'}}
            </v-icon>
          </span>
          <span
              :class="item.is_active ? 'mx-3' : 'red--text mx-3'"
              style="white-space: nowrap; max-width: 30%; overflow: hidden;text-overflow: ellipsis;">
              {{ item.name }}
            </span>
          <span v-if="active">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="primary--text "
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click.stop="favoriteService(item)">
                      <v-icon :color="item.is_favorite && 'yellow'">{{item.is_favorite ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
                  </v-btn>
                  </template>
                <span>dodaj u popularne</span>
            </v-tooltip>
              <v-tooltip top v-if="!item.is_service">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="primary--text "
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click.stop="addService(item)">
                      <v-icon>mdi-plus-outline</v-icon>
                  </v-btn>
                  </template>
                <span>{{ $t('general.add') }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.is_service">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="primary--text "
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click.stop="showHistory(item)">
                      <v-icon>mdi-information</v-icon>
                  </v-btn>
                  </template>
                <span>{{ $t(translation + '.history') }}</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="warning--text"
                      small
                      text
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="editService(item)"
                  >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
               </template>
                <span>{{ $t('general.edit') }}</span>
            </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      class="error--text"
                      :loading="deleteLoadingId === item.id"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="onDelete(item)"
                  >
                    <v-icon>{{ item.is_active ? 'mdi-delete' : 'mdi-backup-restore' }}</v-icon>
                  </v-btn>
               </template>
                <span>{{ $t('general.delete') }}</span>
            </v-tooltip>
            </span>
        </v-row>
      </template>
    </tree-view>
    <universal-dialog
        v-if="dialogHistory.show"
        :show-dialog="dialogHistory.show"
        width="500px"
        @close="dialogHistory.show = false; dialogHistory.title = ''; dialogHistory.item = ''"
    >
      <service-credit-history v-if="dialogHistory.show" :data="dialogHistory.item"/>
    </universal-dialog>

    <!--  DODAVANJE/IZMJENA  -->
    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        @close="closeDialog(); form.selected_item = ''"
    >
      <add-edit-service ref="addEditService" :translation="translation" :selected-data="form" :edit-item="form.item"/>

      <template v-slot:dialogActions>
        <v-sheet class="text-end" width="100%">
          <v-spacer/>
          <v-btn @click="closeDialog(); form.selected_item = ''" :disabled="addLoading" color="error">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
              class="ml-5"
              @click="getFormData"
              :loading="addLoading"
              color="primary">
            {{ form.item !== null ? $t('general.edit') : $t('general.add') }}
          </v-btn>
        </v-sheet>
      </template>
    </universal-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import {mapMutations} from "vuex";

import {showSwalPopUpConfirmation, closeDialog} from "@/utils/functions";

import UniversalTreeView from "@/components/UniversalTreeView";
import UniversalDialog from "@/components/UniversalDialog";
import AddEditService from "@/components/Services/AddEditService";
import {CRUD_VARS} from "@/utils/consts";
import ServiceCreditHistory from "@/components/Services/ServiceCreditHistory.vue";

export default {
  name: "ServicesView",
  components: {
    "tree-view": UniversalTreeView,
    "universal-dialog": UniversalDialog,
    "add-edit-service" : AddEditService,
    "service-credit-history": ServiceCreditHistory
  },
  data() {
    return {
      translation: "services",
      form: {
        item: ""
      },
      apiRead: "/api/services/index_active",
      apiStore: "",
      apiUpdate: "",
      apiDelete: "",
      items: [],
      showLoading: false,
      addLoading: false,
      deleteLoadingId: -1,
      dialogHistory: {
        show: false,
        title: "",
        item: ""
      },
      ...CRUD_VARS
    }
  },
  computed: {
    filter() {
      if (this.search === '') {
        this.open = [];
      }
      return (item, search) => {
        let searchValue = item['name'] + item['description'];
        // if (item['is_service']) {
        //   searchValue += item['file_name'];
        // }
        if (searchValue && searchValue.toLowerCase().includes(search.toLowerCase())) {
          const scId = item['id'];
          // console.log(scId)
          if (!item['is_service']) {
            // console.log('push')
            this.open.push(scId);
          }
          // console.log('open', scId);
          this.openParentItem(scId, null);
          return true;
        }
        return false;
      }
    },
  },
  created() {
    this.getData()
    this.apiStore = "/api/services/store"
    this.apiUpdate = "/api/services/update"
    this.apiDelete = "/api/services/destroy"
    this.sucMessageEdit = this.$t(this.translation + '.sucMessageEdit')
    this.sucMessageStore = this.$t(this.translation + '.sucMessageStore')
    this.sucMessageDelete = this.$t(this.translation + '.sucMessageDelete')
    this.sucMessageReturn= this.$t(this.translation + '.sucMessageReturn')
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    showSwalPopUpConfirmation,
    closeDialog,
    async getFormData() {
      this.loading = true
      const data = await this.$refs.addEditService.getData()

      if (data === null) {
        this.loading = false
        return
      }

      try {
        this.handleSubmitData(data)
      } catch (e) {
        console.log(e, "eeee")
        this.loading = false
      }
    },
    editService(item) {
      this.form.item = null
      this.dialog.title = this.$t('general.edit')
      this.form.item = item
      this.editLoadingId = item.id
      this.dialog.show = true
    },
    addService(item) {
      if (item) {
        this.form.selected_item = item
      }
      this.form.item = null
      this.dialog.title = this.$t('general.add')
      this.dialog.show = true
    },
    getData() {
      this.showLoading = true

      this.$axios.post(this.apiRead, {

        is_report : false,
        is_single_level : false,
        show_inactive_items : true
      }).then((response) => {
        this.items = response.data
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.showLoading = false
      })
    },
    updateNode(nodes, editedNode) {
      for (let node of nodes) {
        if (node.id === editedNode.id) {
          const keys = Object.keys(editedNode)

          keys.forEach(key => {
            node[key] = editedNode[key]
          })
          // node = {...node, ...editedNode}
          return
        }
        if (node.services) {
          this.updateNode(node.services, editedNode)
        }
      }
    },
    callEditApi(data) {
      this.addLoading = true

      this.$axios.post(`${this.apiUpdate}/${data.get('id')}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response) {
          this.loading = false
          this.closeDialog()
          this.updateNode(this.items, response.data)
          this.setSnackbar({color: "success", text: this.sucMessageEdit})
        }
      }).catch((error) => {
        this.loading = false
        if (error.response && error.response.data) {
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.addLoading = false
      })
    },
    handleSubmitData(data) {
      if (this.form.selected_item) {
        // data.service_id = this.form.selected_item.id
        data.append('service_id', this.form.selected_item.id)
      }

      this.loading = true

      // ako je edit odradi put
      if (this.form.item !== null) {
        this.callEditApi(data)
        return
      }
      this.addLoading = true
      this.$axios.post(this.apiStore, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response) {
          this.loading = false

          this.closeDialog()
          if (this.form.selected_item) {
            // ako je kategorija
            if(response.data.service_id !== null) {
              this.form.selected_item.services.push({...response.data, services: []})
            } else {
              // ako je usluga
              this.form.selected_item.services.push(response.data)
            }
          } else {
            if(response.data.service_id === null) {
              response.data.services = []
            }
            this.items.push(response.data)
          }
          // console.log(this.items, "this.items")
          this.setSnackbar({color: "success", text: this.sucMessageEdit})
        }
      }).catch((error) => {
        this.loading = false
        // console.log(error, "prolazi - error")
        if (error.response && error.response.data) {
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.addLoading = false
      })
    },
    callApiDelete(item) {
      this.deleteLoadingId = item.id
      this.$axios.delete(`${this.apiDelete}/${item.id}`).then((response) => {
        if(response && response.data) {
          this.updateNode(this.items, response.data)
        }
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.deleteLoadingId = -1
      })
    },
    async favoriteService(item) {
      const response = await this.showSwalPopUpConfirmation(item.is_favorite ? "Da li ste sigurni da želite da maknete uslugu iz popularnih" : "Da li ste sigurni da želite da prebacite uslugu u popularne", "info")
      if(!response.isConfirmed) {
        return
      }
      this.loading = true

      this.$axios.get(`/api/services/set-unset-favorite-service/${item.id}`).then((response) => {
        if (response) {
          this.loading = false
          this.closeDialog()
          this.updateNode(this.items, response.data)
          this.setSnackbar({color: "success", text: "Uspjesno ste postavili uslugu u popularne"})
        }
      }).catch((error) => {
        this.loading = false
        if (error.response && error.response.data) {
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.addLoading = false
      })
    },
    showHistory(item) {

      this.dialogHistory.item = item
      this.dialogHistory.title = this.$t(this.translation + '.history')
      this.dialogHistory.show = true
    },
    async onDelete(item) {
      const response = await this.showSwalPopUpConfirmation(item.is_active ? this.$t("general.confirm_delete_message") : this.$t("general.confirm_return_message"), "info")
      if(response.isConfirmed) {
        this.callApiDelete(item)
      }
    },
  }
}
</script>

<style scoped>

</style>
