<template>
  <div>
    <!--    <table-picker/>-->
    <data-table-pagination
        ref="dataTable"
        :title="$t(translation + '.title')"
        :headers="headers"
        :translation="translation"
        :append-item="dataTablePaginationData.addItem"
        :edit-item="dataTablePaginationData.editItem"
        :api-read="dataTablePaginationData.apiRead"
        :prop-sort-by="dataTablePaginationData.sortBy"
        @success-append="dataTablePaginationData.addItem = null"
        @success-edit="dataTablePaginationData.editItem = null"
    >
      <template v-slot:toolbarAction>
        <v-btn color="primary" @click="addItem()">
          <v-icon dark>mdi-plus</v-icon>
          {{ $t('general.add') }}
        </v-btn>
        <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-slot:action-buttons="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                icon
                @click="showHistoryPrice(item)"
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{ 'Istorija' }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="warning"
                icon
                @click="editItem(item)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('general.edit') }}</span>
        </v-tooltip>
      </template>
    </data-table-pagination>

    <!--  DIALOG ISTORIJA VRIJEDNOSTI  -->
    <universal-dialog
        v-if="dialogHistory.show"
        :show-dialog="dialogHistory.show"
        width="500px"
        @close="dialogHistory.show = false; dialogHistory.title = ''; dialogHistory.item = ''"
    >
      <credit-price-history v-if="dialogHistory.show" :data="dialogHistory.item"/>

    </universal-dialog>

    <!-- DIALOG ADD/EDIT   -->
    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        @close="closeDialog"
    >
      <add-edit-credits ref="addEditCredits" :translation="translation" :edit-item="form.item"/>

      <template v-slot:dialogActions>
        <v-sheet class="text-end" width="100%">
          <v-spacer/>
          <v-btn @click="closeDialog" :disabled="loading" color="error">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
              class="ml-5"
              @click="getFormData"
              :loading="loading"
              color="primary">
            {{ form.item !== null ? $t('general.edit') : $t('general.add') }}
          </v-btn>
        </v-sheet>
      </template>
    </universal-dialog>
  </div>
</template>

<script>
import DataTablePagination from "@/components/DataTablePagination";
import UniversalDialog from "@/components/UniversalDialog";
import {CRUD_VARS} from "@/utils/consts";
import {mapMutations} from "vuex";
import {
  addItem,
  closeDialog,
  editItem,
  generateHeaders,
  handleSubmitData,
  showSwalPopUpConfirmation
} from "@/utils/functions";
import AddEditCredits from "@/components/Credits/AddEditCredits.vue";
import CreditPriceHistory from "@/components/Credits/CreditPriceHistory.vue";
export default {
  name: "CreditsView",
  components: {
    "data-table-pagination": DataTablePagination,
    "universal-dialog": UniversalDialog,
    "add-edit-credits" : AddEditCredits,
    "credit-price-history" : CreditPriceHistory
    // "table-picker": TablePicker
  },
  data() {
    return {
      translation: "credits_page",
      loading: false,
      dataTablePaginationData: {
        apiRead: '/api/credit_prices/index',
        addItem: null,
        editItem: null,
        sortBy: ['updated_at'],
      },
      dialogHistory: {
        show: false,
        title: "",
        item: ""
      },
      form: {
        fields: [],
        item: ""
      },
      ...CRUD_VARS
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "os_countries.name", text: this.$t(this.translation + '.country')},
        {key: "currencies.name", text: this.$t(this.translation + '.currency')},
        {key: "price_per_credit", text: this.$t(this.translation + '.price')},
        {key: "is_active", text: this.$t(this.translation + '.active')},
        {key: "actions", text: this.$t('general.actions'), sortable: false}
      ])
    },
  },
  created() {
    this.apiStore =  "/api/credit_prices/store"
    this.apiUpdate= "/api/credit_prices/update"
    this.sucMessageEdit= this.$t(this.translation + '.sucMessageEdit')
    this.sucMessageStore= this.$t(this.translation + '.sucMessageStore')
    this.sucMessageReturn= this.$t(this.translation + '.sucMessageReturn')
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    closeDialog,
    addItem,
    showSwalPopUpConfirmation,
    generateHeaders,
    editItem,
    handleSubmitData,
    async getFormData() {
      this.loading = true
      let data = await this.$refs.addEditCredits.getData()

      console.log(data, "data ")
      if (data === null) {
        this.loading = false
        return
      }

      try {
        this.handleSubmitData(data)
      } catch (e) {
        console.log(e, "eeee")
        this.loading = false
      }
    },
    showHistoryPrice(item){
      this.dialogHistory.item = item
      this.dialogHistory.title = this.$t(this.translation + '.history')
      this.dialogHistory.show = true
    }
  }
}
</script>


<style scoped>

</style>