<template>
  <v-row
      justify="end"
      class="mr-0">
    <v-dialog
        persistent
        :value="showDialog"
        max-width="600px">
      <v-card>
        <v-card-title class="primary">
          <span
              class="headline white--text">
            {{ $t(translation + '.title') }}
          </span>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  large
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('general.close') }}
            </span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-on:submit.prevent>
              <v-row>
                <v-col
                    cols="6">
                  <v-text-field
                      v-model="form.name"
                      :label="$t(translation+'.name')+'*'"
                      :rules="[validationRules.required]"
                      autocomplete="off"
                  />
                </v-col>
                <v-col
                    cols="6">
                  <v-combobox
                      :items="types"
                      v-model="form.type"
                      :label="$t(translation+'.types')+'*'"
                      :rules="[validationRules.required]"
                      item-text="name"
                  />
                </v-col>
                <v-col
                    class="ma-0 pt-0 pb-0"
                    cols="12">
                  <v-checkbox
                      class="ma-0 pt-0 pt-0 pb-0"
                      v-model="form.show_all"
                      :label="$t(translation+'.show_all')"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>{{ $t('general.validationMsg') }}</small>
        </v-card-text>
        <v-card-actions>
          <div
              class="flex-grow-1">
          </div>
          <v-btn
              class="primary mr-2"
              dark
              :loading="loading"
              @click="downloadReport()"
          >
            {{ $t(translation + '.download') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {validationRules} from "@/utils/validationRules";


export default {
  props: {
    showDialog: {
      default: false,
      required: true,
      type: Boolean
    },
    defaultName: {
      default: "report",
      required: true,
      type: String
    }
  },
  name: "DownloadReportDialog",
  data() {
    return {
      translation: 'download_report',
      loading: false,
      types: [
        {
          name: "PDF",
          value: "pdf"
        },
        {
          name: "Excel",
          value: "xlsx"
        },
        {
          name: "Word",
          value: "docx"
        },
      ],
      formError: {
        name: '',
        type: {
          name: "PDF",
          value: "pdf"
        },
        show_all: true
      },
      form: {
        name: null,
        type: {
          name: "PDF",
          value: "pdf"
        },
        show_all: true
      }
    }
  },
  computed: {
    validationRules() {
      return validationRules
    },
  },
  methods: {
    downloadReport() {
      this.$emit('download', {
        name: this.form.name,
        type: this.form.type.value,
        show_all: this.form.show_all
      })
      this.closeDialog();
    },
    closeDialog() {
      this.form = {
        name: '',
        type: {
          name: "PDF",
          value: "pdf"
        },
        show_all: true
      }
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
  },
  watch: {
    defaultName: function (val) {
      if (val) {
        this.form.name = this.defaultName
      }
    }
  }
}
</script>
