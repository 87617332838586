import { render, staticRenderFns } from "./CreditsView.vue?vue&type=template&id=f5890bb0&scoped=true"
import script from "./CreditsView.vue?vue&type=script&lang=js"
export * from "./CreditsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5890bb0",
  null
  
)

export default component.exports